// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/logo.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/logo.tsx");
  import.meta.hot.lastModified = "1732203417245.9685";
}
// REMIX HMR END

import clsx from 'clsx';
export const Logo = ({
  size = 'lg'
}) => {
  return <h4 className={clsx('font-extrabold uppercase', {
    'text-2xl': size === 'sm',
    'text-3xl': size === 'md',
    ' text-5xl': size === 'lg'
  })}>
      Ver
      <span className="text-indigo-600">8</span>
    </h4>;
};
_c = Logo;
var _c;
$RefreshReg$(_c, "Logo");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;